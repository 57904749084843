@use '../utils' as *;

/* pulse effect animation */

@-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}
@keyframes pulse {
0% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
}
70% {
	-moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	box-shadow: 0 0 0 45px rgba(255,255,255, 0);
}
100% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}

@keyframes section_stroke{
  0%{
    stroke-dashoffset: 560;
  }
  50%{
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -560;
  }

}


@keyframes section-animation {
	0% {
	  width: 0;
	}
	15% {
	  width: 100%;
	}
	85% {
	  opacity: 1;
	}
	90% {
	  width: 100%;
	  opacity: 0;
	}
	to {
	  width: 0;
	  opacity: 0;
	}
}

@keyframes about-sm{
  100%{
    transform: translateY(60px);
  }
  100%{
    transform: translateY(50px);
  }
}

@keyframes hero-thumb-animation{
  0%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0px);
  }
}

@keyframes tpleftright {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@keyframes tprotate{
	0%{
		transform: rotateY(0deg);
	}
	100%{
	  transform:rotateY(360deg);
	}
}
@keyframes tptranslateY2{
	0% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@-webkit-keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes lineDash {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1000;
  }
}

@keyframes lineDash {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1000;
  }
}


@-webkit-keyframes lineDash-2 {
  0% {
    stroke-dashoffset: -0;
  }
  100% {
    stroke-dashoffset: -1000;
  }
}

@keyframes lineDash-2 {
  0% {
    stroke-dashoffset: -0;
  }
  100% {
    stroke-dashoffset: -1000;
  }
}




@keyframes scroll{
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(8px);
    -moz-transform: translateY(8px);
    -ms-transform: translateY(8px);
    -o-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes RL_smooth {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }



@keyframes slide_up_down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }



@keyframes tptranslateX2{
	0% {
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    -webkit-transform: translatXY(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes scale_up_down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0px;
    stroke-dasharray:3000;
  }
}


@keyframes tpupdown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}


@keyframes tpswing {
  0% {
    -webkit-transform: rotate(6deg);
    -moz-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
    -o-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  100% {
    -webkit-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
}

@keyframes borderanimate2{
  0% {
      transform: translate(-50%, -50%) scale(.8);
  }
60%{
  opacity: 1;
}
    100% {
      transform: translate(-50%, -50%) scale(2.5);
      opacity: 0;
    }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}



@keyframes moving {
  0% {
      transform: translatey(0px);
  }

  25% {
      transform: translatex(20px);
  }
  50% {
    transform: translatey(-20px);
  }
  75% {
    transform: translatex(-20px);
  }

  100% {
      transform: translatey(0px);
  }
}

@keyframes about-circle {
  0%{
      @include transform(translateX(100px));
  }
  100%{
      @include transform(translateX(0px));
  }
}

@keyframes services-triangle {
  0%{
      @include transform(rotate(0deg) translateX(-50px));
  }
  100%{
      @include transform(rotate(360deg) translateY(100px));
  }
}

@keyframes hero-3-dot-2{
  0%{
    transform: translateY(-50px);
  }
  100%{
    transform: translateY(0px);
  }
}


@keyframes tfLeftToRight {
    49% {
        transform: translateX(30%);
    }
    50% {
        opacity: 0;
        transform: translateX(-30%);
    }
    51% {
        opacity: 1;
    }
}


@keyframes rotate2 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
@keyframes rotate3 {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}
@keyframes circle-animations {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@keyframes moving {
    0% {
  
      transform: translatey(0px);
    }
    20%{
        transform: translateX(-50px);
    }
    50% {
      transform: translatey(-40px);
    }
  
    100% {
      transform: translatey(0px);
    }
  }
@keyframes leftright {
    0% {
  
      transform: translatex(0);
    }
    50%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0);
    }
  
  }

@keyframes movinglight {
    0% {
  
      transform: translatey(0px);
    }
    20%{
        transform: translateX(-70px);
    }
    50% {
      transform: translatey(-70px);
    }
  
    100% {
      transform: translatey(0px);
    }
  }
@keyframes movinglight2 {
    0% {
  
      transform: translatey(0px);
    }
    20%{
        transform: translateX(70px);
    }
    50% {
      transform: translatey(70px);
    }
  
    100% {
      transform: translatey(0px);
    }
  }
@keyframes movingtop {
    0% {
  
      transform: translatey(0px);
    }
    20%{
        transform: translatey(70px);
    }
    50% {
      transform: translatey(-70px);
    }
  
    100% {
      transform: translatey(0px);
    }
  }
@keyframes circle-animation {
  0% {
    right: 0;
  }
  50% {
    right: 100%;
  }
  100% {
    right: 0%;
  }
}

@keyframes section_stroke {
  0% {
   stroke-dashoffset:560

  }
  50% {
   stroke-dashoffset:560
  }
  to {
   stroke-dashoffset:0
  }
 }


 @keyframes headerSlideDown {
  0% {
   margin-top:-150px
  }
  100% {
   margin-top:0
  }
 }




 @keyframes movingleftright1 {
  0% {
      -webkit-transform: translateX(0) translateY(0);
      transform: translateX(0) translateY(0);
  }

  40% {
      -webkit-transform: translateX(50px) translateY(-50px);
      transform: translateX(50px) translateY(-50px);
  }

  75% {
      -webkit-transform: translateX(200px);
      transform: translateX(200px);
  }

  100% {
      -webkit-transform: translateY(0) translateX(0);
      transform: translateY(0) translateX(0);
  }
}




@keyframes circle-animation-2 {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes scroll1 {
	0% {
		top: 0%;
	}

	50% {
		top: 95%;
	}

	100% {
		top: 0%;
	}
}

@keyframes movingX {
  0% {
   -webkit-transform:translateY(0) rotateX(0deg);
   transform:translateY(0) rotateX(0deg)
  }
  50% {
   -webkit-transform:translateY(calc(100vh - 100%));
   transform:translateY(calc(100vh - 100%))
  }
  51% {
   -webkit-transform:translateY(calc(100vh - 100%)) rotateX(180deg);
   transform:translateY(calc(100vh - 100%)) rotateX(180deg)
  }
  100% {
   -webkit-transform:translateY(0) rotateX(180deg);
   transform:translateY(0) rotateX(180deg)
  }
 }





@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 20px;
    -webkit-transform: translateZ(150px) ;
    transform: translateZ(150px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes tracking-in-contract-bck-2 {
  0% {
    letter-spacing: 20px;
    -webkit-transform: translateZ(150px)  translateY(150px);
    transform: translateZ(150px)  translateY(150px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}



@keyframes animationglob {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes zoom {
    0%{
        transform:scale(.7);
    }
    50%{
        transform: scale(1);
    }
    100%{
        transform: scale( .7);     
    }
}


@keyframes sliderShape{

  0%,100%{

  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;

    transform: translate3d(0,0,0) rotateZ(0.01deg);

  }

  34%{

      border-radius: 36% 64% 41% 59% / 54% 41% 59% 46% ;

    transform:  translate3d(1px,5px,2px) rotateZ(0.01deg);

  }

  50%{

    transform: translate3d(0,0,0) rotateZ(0.01deg);

  }

  67%{

    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;

    transform: translate3d(-1px,-3px,-2px) rotateZ(0.01deg);

  }

}




@keyframes zoom2 {
    0%{
        transform:scale(.9);
    }
    50%{
        transform: scale(1);
    }
    100%{
        transform: scale(.9);     
    }
}

@keyframes wobble-vertical{
  10% {
      transform: translateY(-10px);
  }
  50% {
      transform: skew(15deg);
  }
  80% {
      transform: rotate(10deg);
  }
  100% {
      transform: translate(0);
  }
}



@include keyframes(section-highlight){
	0%  {
      width: 0%;
    }

    100%{
      width: 100%; 
    }
}


@keyframes widthcalc {
  0% {
      width: 20%;
  }
  50% {
      width: 50%;
  }
  75% {
    width: 90%;
  }
  90% {
      opacity: 75%;
  }
  90% {
      opacity: 100%;
  }
}


/* right bounce */
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -ms-transform: translateY(-35px);
    transform: translateY(-35px);
  }
  60% {
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
/* /right bounce */


// animation  icon-bounce
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    @include transform(translateY(0));
  }
  40% {
    @include transform(translateY(-10px));
  }
  60% {
    @include transform(translateY(-5px));
  }
}


// animatio
@keyframes light-one {
  0% {
    opacity: 1;
  }
  25% {
    opacity: .40;
  }
  50% {
    opacity: .60;
  }
  75% {
    opacity: .80;
  }
  100% {
    opacity: 1;
  }
}
