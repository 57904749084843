/*--------------------------------------------------
    ##Footer
---------------------------------------------------*/
.footer-area {
  position: relative;
  background-color: #080c24;
  padding-bottom: 50px;
  &.style-2 {
    .footer-top {
      padding: 0;
      border-color: rgba(255, 255, 255, 0.1);
    }
  }
  .footer-top {
    background-position: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 37px 0 0px;
    margin-bottom: 120px;
    .single-footer-top {
      display: flex;
      position: relative;
      margin-bottom: 33px;
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: -38px;
        height: 160px;
        width: 61px;
        background: url("~/public/assets/img/footer/rectangle.png");
      }
      .icon {
        flex: none;
        height: 80px;
        width: 80px;
        border-radius: 5px;
        background: var(--main-color);
        line-height: 80px;
        text-align: center;
        position: relative;
        margin: 6px 20px 0 6px;
        &:after {
          content: "";
          position: absolute;
          background: #75c8d8;
          opacity: 0.3;
          height: 80px;
          width: 80px;
          border-radius: 5px;
          left: -6px;
          top: -6px;
        }
      }
      .details {
        h6 {
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
          color: #fff;
          margin-bottom: 4px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #d6d6d6;
          margin-bottom: 0;
        }
      }
    }
  }
  .widget {
    position: relative;
    z-index: 2;
    margin-bottom: 70px;
  }
  .widget-title {
    padding-bottom: 13px;
    margin-bottom: 26px;
    position: relative;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #f8f8f8;
    &:after {
      content: "";
      position: absolute;
      width: 100px;
      height: 3px;
      bottom: 0px;
      left: 0;
      background: linear-gradient(
        90.09deg,
        #75c8d8 -7.63%,
        rgba(250, 67, 24, 0) 99.84%
      );
      transform: translate(0, 50%);
      z-index: -1;
    }
  }
  .widget_about {
    .thumb {
      margin-bottom: 13px;
    }
    .details {
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #fff;
        margin-bottom: 30px;
      }
    }
  }
  .widget_nav_menu {
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        position: relative;
        color: #d6d6d6;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        i,
        svg {
          margin-right: 3px;
        }
        + li {
          margin-top: 16px;
        }
        &:hover {
          &:after {
            background: var(--main-color);
          }
        }
      }
    }
  }
}
.footer-bottom-area {
  background: #161922;
  padding: 21.5px 0;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
    margin-bottom: 0;
    a {
      color: var(--main-color);
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #fff;
      display: inline-block;
      + li {
        border-left: 1px solid #fff;
        padding-left: 9px;
        margin-left: 6px;
      }
    }
  }
}
.footer-top-subscribe {
  display: flex;
  align-items: center;
  h3 {
    color: #fff;
    margin-bottom: 0;
    padding: 50px 0 47px;
  }
  .media-left {
    margin-left: 78px;
    padding-left: 80px;
    padding-right: 58px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
  .thumb {
    flex: none;
  }
  .single-subscribe-inner {
    min-width: 360px;
    flex: none;
  }
}

.footer-area-3 {
  background: #201d2e;
  .footer-widget-area {
    padding: 130px 0 100px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  ul {
    list-style: none;
    padding: 0 !important;
    li {
      a {
        color: #fff;
        padding: 3px 0;
        display: block;
        transition: all 0.2s linear;
        &:hover {
          color: var(--main-color);
        }
      }
    }
  }

  .footer-copyright-area {
    padding: 20px 0;
    color: #fff;
    a {
      color: #fff;
    }

    .footer-social {
      float: right;
      text-align: right;

      .social-link {
        margin: 0;
        padding: 0;
        display: flex;
        gap: 8px;
        align-items: center;

        li {
          display: inline-flex;
          margin: 5px;
          &:first-child {
            margin-left: 5px;
          }
          a {
            display: block;
            text-align: center;
            font-size: 16px;
            color: #fff;
          }
          &:hover {
            .facebook {
              i {
                color: $facebook;
              }
            }
            .twitter {
              i {
                color: $twitter;
              }
            }
            .youtube {
              i {
                color: $youtube;
              }
            }
            .instagram {
              i {
                color: $instagram;
              }
            }
          }
        }
      }
    }
  }
  .footer-widget {
    p {
      color: #fff;
    }
  }
}
