@use '../utils' as *;

/*----------------------------------------*/
/*  17. INTEGRATION CSS START
/*----------------------------------------*/

.tp-integration{
    &-mlr{
        margin-left: 310px;
        margin-right: 310px;
        @media #{$xxxl}{
            margin-left: 200px;
            margin-right: 200px;
        }
        @media #{$xxl}{
            margin-left: 100px;
            margin-right: 100px;
        }
        @media #{$xl}{
            margin-left: 45px;
            margin-right: 45px;
        }
        @media #{$lg,$md,$xs}{
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    &-slider-wrapper{
        border-radius: 30px;
    }
    &-section-space{
        margin-bottom: 50px;
    }
    &-wrapper{
        border-radius: 30px;
        display: inline-block;
    }
    &-subtitle{
        font-weight: 500;
        font-size: 18px;
        line-height: 26px; 
        color: var(--tp-common-blue);   
        margin-bottom: 0;
    }
    &-slider-active-2{
        & .slick-slide{
            padding: 0px 10px;
        }
    }
    &-slider-item{
        background-color: var(--tp-common-white);
        box-shadow: 0px 1px 3px rgba(3, 8, 24, 0.1);
        border-radius: 100px; 
    }
    &-slider-main{ 
        &.slick-slide{
            padding: 15px 10px;
        }
    }
}
.tp-integration-btn{
    @media #{$md,$xs}{
        margin-top: 30px;
    }
}

.tp-intigration-five{
    &-space{
        padding-top: 160px;
        padding-bottom: 240px;
        @media #{$xs}{
            padding-top: 120px;
            padding-bottom: 120px;
        }
    }
    &-section-box{
        & p{
            padding-bottom: 30px;
        }
    }
    &-circle-img-1{
        position: absolute;
        top: 0%;
        left: 0%;
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-2{
        position: absolute;
        top: 66%;
        left: -8%;
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-3{
        position: absolute;
        top: 78%;
        left: 5%;
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-4{
        position: absolute;
        top: 123%;
        left: -7%;
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-5{
        position: absolute;
        top: 103%;
        left: 18%;
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-6{
        position: absolute;
        top: 103%;
        right: 18%;
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-7{
        position: absolute;
        top: -3%;
        right: 7%;
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-8{
        position: absolute;
        top: 38%;
        right: 6%;
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-9{
        position: absolute;
        top: 35%;
        right: -13%;
        @media #{$xxl}{
            top: 41%;
            right: -9%;
        }
        @media #{$xl}{
            top: 70%;
            right: 0;
        }
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-10{
        position: absolute;
        bottom: -43%;
        right: -4%;
        & img{
            box-shadow: 20px 30px 100px rgba(5, 12, 31, 0.1);
            border-radius: 50%;
        }
    }
    &-circle-img-11{
        position: absolute;
        bottom: -34%;
        left: 3%;
        z-index: -1;
        animation: moving 9s linear infinite;
    }
    &-circle-img-12{
        position: absolute;
        bottom: 49%;
        left: 7%;
        z-index: -1;
        animation: movingleftright1 infinite 18s;
    }
    &-circle-img-13{
        position: absolute;
        bottom: -42%;
        right: 14%;
        animation: moving 9s linear infinite;
    }
    &-circle-img-14{
        position: absolute;
        bottom: 21%;
        right: -11%;
        z-index: -1;
        animation: movingleftright1 infinite 18s;
        @media #{$xxl}{
            bottom: 13%;
            right: -6%;
        }
    }
}

.tp-feature-inner{
    & .tp-feature-five-wrapper{
        & .tp-feature-five-item{
            box-shadow: -1px -1px 4px rgba(20, 17, 37, 0.04), 0px 1px 4px rgba(32, 33, 36, 0.06);
        }
    }
}