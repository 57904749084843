@use '../utils' as *;

/*----------------------------------------*/
/*  19. PRICE CSS START
/*----------------------------------------*/

.tp-price{
    &__pl-pr{
        margin: 0px 60px;
        background: linear-gradient(180deg, #F6F5FA 0.01%, rgba(246, 245, 250, 0) 100%);
        border-radius: 40px;
        @media #{$xl}{
            margin: 0px 15px;
        }
        @media #{$lg,$md,$xs}{
            margin: 0px;
        }
    }
    &__section-box{
        & p{
            font-size: 18px;
        }
    }
    &__item{
        background-color: var(--tp-common-white);
        box-shadow: 0px 1px 0px rgba(1, 16, 61, 0.1), 0px -20px 70px rgba(1, 16, 61, 0.03);
        border-radius: 30px;
        padding: 50px 60px;
        overflow: hidden;
        @media #{$lg}{
            padding: 35px 28px;
        }
        @media #{$xs}{
            padding: 30px;
        }
        &:hover{
            & .tp-price__btn{
                & a{
                    opacity: 1;
                    -webkit-transform: translateX(0);
                    -ms-transform: translateX(0);
                    transform: translateX(0);
                    visibility: visible;
                    & i{
                        transform: translateY(2px);
                        margin-left: 10px;
                    }
                }
                & span{
                    transform: translateX(30px);
                }
            }
        }
        &.active{
            background: linear-gradient(358.1deg, #5956E9 49.57%, #908DFF 107.48%);
            border-radius: 30px;
            & .tp-price__title-sm{
                color: var(--tp-common-white);
            }
            & .tp-price__title-box{
                & p{
                    color: var(--tp-common-white);
                    border-color: rgba(255, 255, 255, 0.2);
                }
            }
            & .tp-price__feature{
                & ul{
                    & li{
                        color: var(--tp-common-white);
                        & svg{
                            & path.price-path-2{
                                color: #fff;
                            }
                            & path.price-path-1{
                                color: #fff;
                            }
                        }
                    }
                }
            }
            & .tp-price__btn{
                background-color: var(--tp-common-white);
                border-radius: 30px;
                & a{
                    background-color: #18171F;
                }
            }
        }
    }
    &__btn{  
        overflow: hidden;
        position: relative;
        & span{
            transform: translateX(0px);
            transition: .3s;
        }
        & a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 45px;
            border-radius: 30px;
            background-color: var(--tp-common-blue);
            color: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            z-index: 10;
            opacity: 0;
            -webkit-transform: translateX(-50px);
            -ms-transform: translateX(-50px);
            transform: translateX(-50px);
            visibility: hidden;
        }
    }
    &__shape-1{
        position: absolute;
        top: -6px;
        right: -5px;
    }
    &__shape-2{
        position: absolute;
        top: -6px;
        right: -5px;
    }
    &__shape-3{
        position: absolute;
        top: -6px;
        right: -5px;
    }
    &__feature{
        margin-bottom: 45px;
        & ul{
            & li{
                font-weight: 600;
                font-size: 14px;
                position: relative;
                padding-left: 32px;
                margin-bottom: 20px;
                list-style-type: none;
                &.inactive{
                    & span{
                       & svg{
                            & path.price-path-3{
                                color: #D9DADC;
                            }
                            & path.price-path-2{
                                color: #D9DADC;
                            }
                            & path.price-path-1{
                                color: #D9DADC;
                            }
                        }
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
                & span{
                    position: absolute;
                    top: 0;
                    left: 0;
                    & svg{
                        & path.price-path-3{
                            color: #FF94BA;
                        }
                        & path.price-path-2{
                            color: #6865FF;
                        }
                        & path.price-path-1{
                            color: #6865FF;
                        }
                    }
                }
            }
        }
    }
    &__icon{
        padding-bottom: 12px;
    }
    &__title-sm{
        font-weight: 700;
        font-size: 22px;
        padding-bottom: 5px;
    }
    &__title-box{
        & p{
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 35px;
            padding-bottom: 35px;
            border-bottom: 1px solid rgba(1, 16, 61, 0.1);
            @media #{$lg}{
                font-size: 13px;
                margin-bottom: 30px;
                padding-bottom: 30px;
            }
        }
    }
}

.tp-price-header{
    padding-left: 40px;
    &-img{
        padding-bottom: 20px;
    }
    &-content{
        & p{
            font-weight: 500;
            font-size: 14px;
            line-height: 24px; 
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 0;
            & span{
                font-weight: 600;
                color: var(--tp-common-white);
            }
        }
    }
}
.tp-price-top{
    &-item{
        width: 33.33%;
        float: left;
        padding: 40px 20px;
        &.active{
            background: rgba(255, 255, 255, 0.06);
            border-radius: 20px 20px 0px 0px;
            & .tp-btn-service{
                background-color: var(--tp-common-yellow-3);
                color: var(--tp-common-black);
                border-color: var(--tp-common-yellow-3);
                transition: .3s;
                &:hover{
                    background-color: var(--tp-common-white);
                    color: var(--tp-common-black);
                    border-color: var(--tp-common-white);
                }
            }
        }
    }
    &-tag-wrapper{
        & span{
            font-weight: 700;
            font-size: 16px;
            line-height: 14px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: var(--tp-common-white); 
            padding-bottom: 10px;
            display: inline-block;  
        }
        & p{
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(255, 255, 255, 0.7);
            padding-bottom: 10px;
        }
    }
    &-title-wrapper{
        & h4{
            font-weight: 600;
            font-size: 50px;
            line-height: 49px;
            color: #FFFFFF; 
            padding-bottom: 8px;
            & span{
                font-weight: 500;
                font-size: 18px;
                line-height: 14px;
            }
            & em{
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 17px;
                color: #FFFFFF;
                margin-right: 5px;
                transform: translateY(-23px);
                display: inline-block;
            }
        }
        & p{
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 0;
            padding-bottom: 30px;
        }
    }
}

.tp-price-feature{
    &-wrapper{
        border: 1px solid rgba(255, 255, 255, 0.14);
        border-radius: 20px;
    }
    &-box{
       border-radius: 20px 0 0 20px; 
       background: rgba(255, 255, 255, 0.06);
    }
    &-item{
        height: 65px;
        line-height: 65px;
        padding: 0px 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.14);
        &:last-child{
            border-bottom: 0;
        }
        & span{
            color: var(--tp-common-white);
            font-weight: 600;
            font-size: 16px;
            line-height: 14px;
            margin-right: 5px;
        }
    }
    &-info-item{
        width: 33.33%;
        float: left;
        &.active{
            background: rgba(255, 255, 255, 0.06);
        }
    }
    &-info{
        height: 65px;
        line-height: 65px;
        padding: 0px 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.14);
        &:last-child{
            border-bottom: 0;
        }
        & span{
            font-weight: 400;
            font-size: 16px;
            line-height: 14px;
            color: var(--tp-common-white); 
        }
    }
    &-tooltip{
        background-color: var(--tp-common-white);
        padding:10px 20px;
        border-radius: 14px;
        position: absolute;
        bottom: 20px;
        left: -30px;
        width: 270px;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
        &::after{
            position: absolute;
            content: "";
            left: 35px;
            bottom: 2px;
            width: 18px;
            height: 18px;
            background-color: var(--tp-common-white);
            -webkit-transform: rotate(45deg) translateY(50%);
            -moz-transform: rotate(45deg) translateY(50%);
            -ms-transform: rotate(45deg) translateY(50%);
            -o-transform: rotate(45deg) translateY(50%);
            transform: rotate(45deg) translateY(50%);
        }
        & p{
            margin-bottom: 0;
            font-size: 14px;
        }
    }
}

.tp-price-feature-tooltip-box{
    display: inline-block;
    cursor: pointer;
    &:hover{
        & .tp-price-feature-tooltip{
          visibility: visible;
          opacity: 1;
          bottom: 45px;
        }  
    }
}

.tp-price-table{
    @media #{$lg,$md,$xs}{
        overflow-x: scroll;
        scrollbar-color: rgba(255, 255, 255, 0.559) rgba(255, 255, 255, 0.14);
    }  
}
.tp-price-table-wrapper{
    width: 1170px;
}

.plan-section-box {
    & p{
        @media #{$xs}{
            & br{
                display: none;
            }
        }  
    }
}


.tp-price{
    &__btn-box{
        display: inline-block; 
    }
    &__btn-bg{
        background: #FFFFFF;
        box-shadow: 0px 1px 1px rgba(18, 20, 32, 0.14);
        border-radius: 50px;
        position: relative;
        @media #{$xs}{
            transform: translateY(40px);
        } 
        & button{
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            color: #222;
            position: relative;
            z-index: 999;
            padding: 18px 30px;
            transition: .3s;
            &.active{
                color: var(--tp-common-white);
            }
            &.monthly.active{
                & ~ .test{
                    transform: translateX(0px);
                }
            }
            &.yearly.active{
                transform: translateX(-10px);
            }
        }

        @at-root{
            .test{
                position: absolute;
                transition: transform .2s ease-in-out;
                will-change: transform;
                width: 100px;
                height: calc(100% - 8px);
                position: absolute;
                left: 5px;
                top: 4px;
                display: block;
                content: "";
                background: #6865FF;
                box-shadow: 0px 2px 2px -1px rgba(89, 86, 233, 0.15), 0px 0px 1px rgba(89, 86, 233, 0.1);
                border-radius: 30px;
                transform: translateX(104px);
            }
        }
    }
}

.price-shape-line{
    position: absolute;
    right: 35%;
    top: -18px;
    -webkit-animation: lineDash 90s linear infinite;
    animation: lineDash 90s linear infinite;
    @media #{$md,$xs}{
        right: 25%;
    } 
    @media #{$sm}{
        right: 17%;
    } 
    
}

.price-offer-badge{
    height: 50px;
    width: 50px;
    background: linear-gradient(149deg, #FF4386 -12.19%, #413DFF 89.78%);
    box-shadow: 0px 1px 3px rgba(75, 8, 69, 0.3);
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    right: 31%;
    top: -33px;
    @media #{$md,$xs}{
        right: 20%;
    }
    @media #{$sm}{
        right: 11%;
    }
    & span{
        color: var(--tp-common-white);
        font-weight: 800;
        font-size: 11px;
        line-height: 1.2;
        text-align: center;
        letter-spacing: -0.02em;       
        display: inline-block;
        margin-top: 12px;
    }
}

.tp-price__section-box {
    @media #{$xl}{
        & .tp-section-title{
            & br{
                display: none;
            }
        }
    }
}



.price-banner{
    &-title{
        font-weight: 700;
        font-size: 70px;
        line-height: 1;
        letter-spacing: -0.02em; 
        color: #FFFFFF;
        padding-bottom: 22px;
        @media #{$md}{
            font-size: 63px;
        }  
        @media #{$xs}{
            font-size: 48px;
            padding-bottom: 12px;
        }  
        &.anime_text{
            transform:translateX(80px);
            overflow: hidden;
            opacity: 0;
            font-style: normal;
        }
    }
    &-title-box{
        & p{
            font-weight: 500;
            font-size: 17px;
            line-height: 16px;
            color: #FFFFFF;
            margin-bottom: 0;
            @media #{$md}{
                font-size: 14px;
            } 
        }
    }
}

.price-inner-white-bg{
    margin-top: -225px;
    background-color: var(--tp-common-white);
    border-radius: 40px 40px 0 0;
    & .tp-price-table-wrapper{
        padding-top: 40px;
    }
    & .tp-price-feature-wrapper{
        border-color: #EFF1F7; 
    }
    & .tp-price-feature-box{
        background: #F7F9FC;
    }
    & .tp-price-feature-item{
        border-color: #EFF1F7;
        & span{
            color: var(--tp-common-black);
        }
    }
    & .tp-price-feature-info{
        border-color: #EFF1F7;
        & span{
            color: var(--tp-common-black);
        }
    }
    & .tp-price-feature-info-item{
        &.active{
            background: #F7F9FC;
        }
    }
    & .tp-price-header-content{
        & p{
            color: #5F6168;
            & span{
                color: #202124;
            }
        }
    }
    & .tp-price-top-item.active{
        background: #F7F9FC;
        & .tp-price-top-title-wrapper{
            & .tp-btn-service{
                background-color: var(--tp-common-blue-4);
                color: var(--tp-common-white);
                border-color: var(--tp-common-blue-4);
                &:hover{
                    background-color: var(--tp-common-black);
                    color: var(--tp-common-white);
                }
            }
        }
    }
    & .tp-price-top-tag-wrapper{
        & span{
            color: var(--tp-common-black);
        }
        & p{
            color: #5F6168;;
        }
    }
    & .tp-price-top-title-wrapper{
        & h4{
            color: var(--tp-common-black);
        }
        & p{
            color: #5F6168;
        }
        & .tp-btn-service{
            background-color: var(--tp-common-black);
            color: var(--tp-common-white);
            &:hover{
                background-color: var(--tp-common-blue-4);
                color: var(--tp-common-white);
                border-color: var(--tp-common-blue-4);
            }
        }
    }
    & .tp-price-feature-tooltip{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}

.tp-price__btn-box{
    position: relative;
    & .tp-price__btn-line{
        position: absolute;
        right: -20px;
        top: -55px;
        animation: lineDash-2 90s linear infinite;
    }
    & .tp-price__btn-offer-tag{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: linear-gradient(146.58deg, #FF96F9 11.28%, #C32BAC 43.24%);
        box-shadow: 0px 1px 3px rgba(75, 8, 69, 0.3);
        display: inline-block;
        text-align: center;
        position: absolute;
        top: -75px;
        right: -75px;
        z-index: 2222;
        & span{
            font-weight: 600;
            font-size: 11px;
            letter-spacing: -0.02em;
            color: var(--tp-common-white);
            display: inline-block;
            text-align: center;
            transform: translateY(11px);
        }
    }
}

.tp-price__btn-inner{
    & .tp-price__btn-bg{
        & .test{
            background-color: var(--tp-common-blue-4);
        }
    }
}

.pr-feature{ 
    &-wrapper{
        background: #F0F2F5;
        box-shadow: 0px 1px 2px rgba(32, 33, 36, 0.14);
        border-radius: 20px 20px 0px 0px;
        padding: 30px 40px;
        padding-right: 0;
    } 
    &-head{
        & ul{
            padding-left: 20px;
            & li{
                width: 33.33%;
                float: left;
                text-align: center;
                list-style-type: none;
            }
        }
    }
    &-title{
        font-weight: 700;
        font-size: 22px;
        line-height: 1.1;
        color: var(--tp-common-black);
        margin-bottom: 0;  
    }   
    &-title-sm{
        font-weight: 400;
        font-size: 17px;
        line-height: 1.1;
        color: var(--tp-common-black);
    }
    &-height{
        height: 100px;
        padding: 0px 40px;
        padding-right: 0;
    }
    &-item{
        & h5{
            font-weight: 700;
            font-size: 15px;
            line-height: 14px;
            text-transform: uppercase; 
            color: #202124;
            padding-bottom: 5px;
        }
    }
    &-bottom{
        & ul{
            text-align: center;
            padding-left: 20px;
            & li{
                width: 33.33%;
                float: left;
                list-style-type: none;
                & span{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 14px;
                    color: var(--tp-common-black);
                }
                & i{
                    height: 26px;
                    width: 26px;
                    border-radius: 50%;
                    color: var(--tp-common-blue-4);
                    font-size: 12px;
                    background: rgba(96, 14, 228, 0.06);
                    line-height: 29px;
                    &.times{
                        color:#5F6168;
                        background-color: #EAEAEA;
                        font-size: 14px;
                    }
                }
            }
        }
    }  
}

.pr-feature-wrapper-2{
    & .pr-feature-height{
        &:nth-child(2n){
            background-color: #F7F9FC;  
        }
    }
}

.pr-feature-item{
    & .tp-btn-service{
        &:hover{
            background-color: var(--tp-common-blue-4);
            border-color: var(--tp-common-blue-4);
        }
    }
    &.active{
        & .tp-btn-service{
            background-color: var(--tp-common-blue-4);
        }
    }
}


.price-inner-white-bg{
    @media #{$lg,$md,$xs}{
        overflow-x: scroll;
        scrollbar-color: rgba(255, 255, 255, 0.559) rgba(255, 255, 255, 0.14);
    } 
}

.pr-feature-main{
    @media #{$lg,$md,$xs}{
        width: 930px;
    }
}


.pr-feature-box{
    @media #{$lg,$md,$xs}{
        overflow-x: scroll;
        scrollbar-color: rgba(255, 255, 255, 0.559) rgba(255, 255, 255, 0.14);
    }
}
