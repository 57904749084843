@use '../utils' as *;

/*----------------------------------------*/
/*  05. BLOG CSS START
/*----------------------------------------*/

.tp-blog{
    &-thumb{
        margin-bottom: 30px;
        border-radius: 30px;
        & img{
            border-radius: 30px;
            width: 100%;
            transition: transform 1.5s ease;
        }
        &:hover{
            & img{
                transform: scale(1.2);
            } 
        }
    }
    &-meta{
        margin-bottom: 12px;
    }
    &-category{
        & span{
            font-weight: 700;
            font-size: 12px;
            line-height: 8px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: var(--tp-common-blue);
            font-family: var(--tp-ff-urban);
            border: 1px solid rgba(91, 108, 255, 0.1);
            border-radius: 30px;
            display: inline-block;
            padding: 8px 12px;
        }
    }
    &-date{
        & span{
            font-weight: 500;
            font-size: 14px;
            line-height: 12px;            
            color: #787B83;
            padding-left: 12px;
            margin-left: 12px;
            position: relative;
            &::after{
                position: absolute;
                content: '';
                top: 8px;
                left: 0;
                height: 4px;
                width: 4px;
                border-radius: 50%;
                background-color: #5F6168;;
            }
        }
    }
    &-title-box{
        padding-bottom: 22px;
        margin-bottom: 20px;
        border-bottom: 1px solid #E9EBF1;
    }
    &-title-sm{
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: var(--tp-common-black);
        font-family: var(--tp-ff-urban);
        @media #{$lg}{
            font-size: 20px;
            line-height: 28px;
            & br{
                display: none;
            }
        }
        @media #{$xs}{
            font-size: 21px;
            line-height: 29px;
            & br{
                display: none;
            }
        }
        &:hover{
            color: var(--tp-theme-1);
        }
    }
    &-avata{
        margin-right: 15px;
    }
    &-author-info{
        & h5{
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: var(--tp-common-black);
            font-family: var(--tp-ff-urban);
            margin-bottom: 3px;
        }
        & span{
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;           
            color: #787B83;
            font-family: var(--tp-ff-urban);
        }
    }
}
.category-color-2{
    & span{
        border-color:rgba(255, 154, 0, 0.2);
        color: #FF9A00;
    }
}
.category-color-3{
    & span{
        border-color:rgba(33, 182, 65, 0.1);
        color: var(--tp-common-green);
    }
}
.tp-blog-four{
    &-section-space{
        margin-bottom: 60px;
    }
    &-item{
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 30px;
        &:hover{
          & .tp-blog-four-img{
            & img{
                transform: scale(1.1) rotate(2deg);
            }
          }  
        }
    }
    &-img{
        & img{
            border-radius: 20px 20px 0 0;
            transition: .6s;
            height: auto;
        }
    }
    &-content-wrapper{
        padding: 35px 40px 50px 40px;
        @media #{$md}{
            padding: 35px 30px 50px 30px;
        }
        @media #{$xs}{
            padding: 20px;
        }
    }
    &-meta{
        margin-bottom: 25px;
        & span{
            &.child-1{
                font-weight: 600;
                font-size: 14px;
                line-height: 12px;          
                text-transform: uppercase;
                color: var(--tp-common-white);
                padding-right: 15px;
                margin-right: 15px;
                position: relative;
                &::after{
                    position: absolute;
                    top: 3px;
                    right: 0;
                    width: 1px;
                    height: 15px;
                    background-color: rgba(255, 255, 255, 0.2);
                    content: "";
                }
            }
            &.child-2{
                font-weight: 600;
                font-size: 14px;
                line-height: 12px;         
                color: var(--tp-common-white);
                opacity: .8;
            }
        }
    }
    &-title-sm{
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        color: var(--tp-common-white);
        padding-bottom: 10px;
        transition: .3s;
        display: inline-block;
        @media #{$lg}{
            font-size: 25px;
        }
        @media #{$md}{
            font-size: 20px;
            line-height: 24px;
        }
        @media #{$xs}{
            font-size: 19px;
            line-height: 24px;
        }
        &:hover{
            color: var(--tp-common-yellow-3);
        }
    }
    &-info{
        & p{
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;                       
            color: rgba(255, 255, 255, 0.7);
            padding-bottom: 20px;
            @media #{$md}{
                font-size: 16px;
                line-height: 23px;
            }
            @media #{$xs}{
                font-size: 16px;
                line-height: 23px;
            }
        }
    }
}
.tp-blog-four-section-box{
    @media #{$md,$xs}{
        margin-bottom: 40px;
    }
}

.blog-grid{
    &-slider-height{
        min-height: 500px;
        @media #{$md,$sm}{
            height: 450px;
        }
    }
    &-slider-bg{
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(269.88deg, rgba(2, 6, 17, 0) -0.76%, rgba(1, 4, 10, 0) 46.85%, rgba(0, 0, 0, 0.72) 99.9%, rgba(0, 0, 0, 0.72) 99.9%);
            filter: drop-shadow(0px 1px 3px rgba(1, 16, 61, 0.1));
            border-radius: 20px;
        }
    }
    &-slider-wrapper{
        padding: 30px 100px;
        position: relative;
        z-index: 5;
        @media #{$xs}{
            padding: 25px;
            & br{
                display: none;
            }
        }
        @media #{$sm}{
            padding: 80px;
            & br{
                display: none;
            }
        }
    }
    &-slider-meta{
        padding-bottom: 13px;
        & span{
            &.child-one{
                font-weight: 600;
                font-size: 14px;
                line-height: 12px;
                color: #202124;
                background-color: var(--tp-common-white);
                padding: 8px 20px;
                display: inline-block;
                border-radius: 30px;
                margin-right: 13px;
            }
            &.child-two{
                font-weight: 500;
                font-size: 14px;
                line-height: 6px;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
    &-slider-title{
        font-weight: 700;
        font-size: 40px;
        line-height: 50px; 
        color: #FFFFFF;
        padding-bottom: 5px;
        transition: .3s;
        &:hover{
            color: var(--tp-common-yellow-3);
        }
        @media #{$md}{
            font-size: 35px;
        }
        @media #{$xs}{
            font-size: 26px;
            line-height: 35px;
        }
    }
    &-slider-title-box{
        & p{
            font-weight: 500;
            font-size: 17px;
            line-height: 26px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 35px;
        }
    }
}

.blog-grid-arrow{
    & button{
        font-size: 20px;
    }
    & .grid-next{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -11px;
        z-index: 9;
        & i{
            transform: translateX(18px) translateY(3px);
        }
    }
    & .grid-prev{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -1px;
        z-index: 9;
        & i{
            transform: translateX(32px) translateY(3px);
        }
    }
}


.postbox__wrapper {
    @media #{$xs,$md} {
        padding-right: 0px;
        margin-bottom: 50px;
    }
}

.postbox {
    &__p-right{
     margin-right: 20px;
     @media #{$xs,$md,$lg} {
        margin-right: 0px;
    }
    }
    &__thumb {
        & .play-btn {
            position: absolute;
            top: calc(50% - 20px);
            left: 50%;
            @include transform(translate(-50%, -50%));
            z-index: 1;
            height: 90px;
            width: 90px;
            background-color: var(--tp-common-white);
            text-align: center;
            line-height: 90px;
            color: var(--tp-common-black);
           border-radius: 50%;
           animation: pulse 2s infinite;
           cursor: pointer;
            &:hover {
                background-color: var(--tp-common-blue-4);
                color: var(--tp-common-white);
            }
        }

        & img {
            border-radius: 20px;
            margin-bottom: 40px;
        }
    }

    &__audio {
        height: 100%;
        width: 100%;

        & iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    &__item {

        &-single {
            &:hover {
                @include box-shadow(none);
            }
        }
    }

    &__content {

        & p {
            & img {
                margin-bottom: 30px;
                max-width: 100%;
                border-radius: 10px;
                @media #{$md,$xs} {
                    width: 100%;
                }
            }
        }

        &-single {
            padding-left: 0;
            padding-right: 0;
            border: none;
        }
    }

    &__title {
        color: var(--tp-common-black);
        line-height: 1.3;
        font-weight: 700;
        font-size: 30px;
        color: #202124;
        @media #{$xl} {
            font-size: 33px;
        }

        @media #{$lg} {
            font-size: 28px;
        }

        @media #{$md} {
            font-size: 33px;
        }

        @media #{$sm} {
            font-size: 30px;
            & br{
                display: none;
            }
        }

        @media #{$xs} {
            font-size: 25px;
            & br{
                display: none;
            }
        }

        & a {
            &:hover {
                color: var(--tp-common-blue-4);
            }
        }
    }

    &__meta {
        margin-bottom: 20px;
        & span {
            font-weight: 500;
            font-size: 14px;
            line-height: 12px;
            color: #888888;
            display: inline-block;
            margin-right: 12px;
            &:first-child{
                padding: 8px 12px;
                border: 1px solid #F0F0F0;
                border-radius: 20px;
            }
            &:last-child {
                margin-right: 0;
            }

            & i {
                color: var(--tp-theme-1);
                margin-right: 3px;
                font-weight: 500;
            }

            &:hover {
                color: var(--tp-common-blue-4);
            }
        }
    }

    &__text {
        padding-right: 30px;
        & img {
            max-width: 100%;
        }

        & p {
            font-size: 17px;

            @media #{$xl} {
                font-size: 16px;
            }
        }

        &-single {
            & p {
                margin-bottom: 15px;
            }
        }
    }

    &__slider {
        & button {
            position: absolute;
            left: 50px;
            top: 50%;
            @include transform(translateY(-50%));
            z-index: 1;
            font-size: 30px;
            color: var(--tp-common-white);

            &.slick-next {
                left: auto;
                right: 50px;

                @media #{$xs} {
                    right: 10px;
                }
            }

            @media #{$xs} {
                left: 10px;
            }
        }
    }
    &__comment {
        & ul {
            & li {
                margin-bottom: 30px;
                padding-bottom: 30px;
                list-style: none;
                border-bottom: 1px solid var(--tp-border-1);
                &:last-child{
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: 0;
                }
                &.children {
                    margin-left: 100px;
                    @media #{$xs} {
                        margin-left: 0px;
                    }
                }
            }
        &-title {
            font-size: 20px;
            font-weight: 500;
            color: var(--tp-common-black);
            margin-bottom: 40px;
            margin-right:20px;
        }
        }

        &-title {
            font-weight: 600;
            font-size: 26px;
            line-height: 18px;
            letter-spacing: -0.02em;
            color: #202124;
            margin-bottom: 40px;
        }

        &-avater {
            & img {
                width: 50px;
                height: 50px;
                @include border-radius(50%);
            }
        }

        &-name {
            margin-bottom: 5px;

            & h5 {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px; 
                color: var(--tp-common-black);
                padding-right: 10px;
                margin-right: 10px;
                position: relative;
                &::after{
                    content: "";
                    height: 4px;
                    width: 4px;
                    background-color: var(--tp-common-black);
                    display: inline-block;
                    position: absolute;
                    right: -1px;
                    bottom: 4px;
                }
            }

            & span {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #888888;
            }
        }

        &-text {
            @media #{$xs} {
                margin-left: 0;
                margin-top: 15px;
            }

            & p {
                font-size: 16px;
                margin-bottom: 15px;
                @media #{$lg}{
                    font-size: 15px;
                    & br{
                        display: none;
                    }
                }
                @media #{$xs} {
                    & br {
                        display: none;
                    }
                }

            }
        }
        &-reply {
            margin-top: 10px;
            & a {
                display: inline-block;
                height: 30px;
                line-height: 27px;
                padding: 0 18px;
                font-weight: 500;
                font-size: 13px;
                color: #202124;
                border: 1px solid #F0F0F0;
                border-radius: 20px;
                transition: .3s;
                &:hover {
                    color: var(--tp-common-white);
                    background: var(--tp-common-blue-4);
                    border-color: var(--tp-common-blue-4);
                    & svg{
                        color: var(--tp-common-white);
                    }
                }
                & svg{
                    transform: translateY(-2px);
                    margin-right: 6px;
                    color: #202124;
                    transition: .3s;
                }
            }
        }

        &-agree {
            margin-bottom: 30px;
            & .form-check-label {
                font-weight: 500;
                font-size: 14px;
                line-height: 1.3;
                color: #87888A;
            }
            & .form-check-input {
                &:checked {
                    background-color: var(--tp-common-blue-4);
                    border-color: transparent;
                }
                &:focus {
                    box-shadow:none;
                }
                &.form-check-input[type="checkbox"] {
                    border-radius: 2px;
                    margin-top: 4px;
                }
            }
        }
    }

    &__tag {

        & span {
            font-size: 20px;
            margin-bottom: 17px;
            color: var(--tp-common-black);
            margin-right: 30px;
            font-weight: 500;
        }
    }

    &__social {
        @media #{$lg,$md,$xs} {
            margin-top: 40px;
        }

        & span {
            font-size: 20px;
            color: var(--tp-common-black);
            font-weight: 500;
        }

        & a {
            font-size: 15px;
            padding-left: 30px;

            & .tp-linkedin {
                color: #0E6BA1;
            }

            & .tp-pinterest {
                color: #D70220;
            }

            & .tp-facebook {
                color: #0E6BA1;
            }

            & .tp-twitter {
                color: #36B6ED;
            }
        }
    }
    &__navigation{
        &-more{
            @media #{$xs}{
                flex-wrap: wrap;
            }
        }
        &-img{
            margin-right: 15px;
            & img{
                border-radius: 12px;
            }
        }
        &-left{
            @media #{$xs}{
                margin-bottom: 30px;
            } 
        }
        &-content{
            & a{
                &:hover{
                    & span{
                        color: var(--tp-common-blue-4);
                    }
                }
            }
            & span{
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #5F6168;
                padding-bottom: 15px;
                display: inline-block;
                transition: .3s;
                & i{
                    margin-right: 10px;
                }
            }
            & h5{
                font-weight: 700;
                font-size: 18px;
                line-height: 16px;
                color: #202124; 
                transition: .3s;
                @media #{$xs}{
                    font-size: 16px;
                }
                &:hover{
                    color: var(--tp-common-blue-4);
                }
                @media #{$lg}{
                    font-size: 15px;
                }
            }
        }
    }
    &__navigation-right{
        & .postbox__navigation-img{
            margin-right: 0;
            margin-left: 15px;
        }
        & .postbox__navigation-content{
            & span{
                & i{
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }
    }
}

.blog-grid-inner{
    & .tp-blog-item{
        border: 1px solid #F0F0F0;
        border-radius: 30px;
        transition: .3s;
        background-color: var(--tp-common-white);
        &:hover{
            border: 1px solid #F0F0F0;
            box-shadow: 0px 10px 20px rgba(1, 16, 61, 0.1);
            border-radius: 30px;
        }
    }
    & .tp-blog-content{
        padding: 30px;
        @media #{$xs}{
            padding: 25px;
        }
    }
    & .tp-blog-thumb{
        margin-bottom: 0;
        border-radius: 20px 20px 0 0;
        & img{
            border-radius: 20px 20px 0 0;
            height: auto;
        }
    }
    & .tp-blog-title-box{
        margin-bottom: 0px;
        border-bottom: none;
    }
    & .tp-blog-title-sm{
        font-family: var(--tp-ff-body);
        & br{
            display: none;
        }
    }
}







.form-label-action{
    &.active{
        & .postbox__comment-input{
            & label{
                top: 0;
                color: var(--tp-common-blue-4);
                font-size: 13px;
                font-weight: 400;
            }
            & span{
                top: -14px;
                color: var(--tp-common-blue-4);
                font-size: 13px;
                font-weight: 400;
            }
        }
    }
}



.blog-grid-bg{
    position: relative;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(247, 249, 252, 0) 0%, #F7F9FC 100%);
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 50%;
        width: 100%;
        display: inline-block;
        z-index: -1;
    }
}

.postbox__comment-form-title {
	margin-bottom: 30px;
    font-weight: 600;
    font-size: 26px;
    line-height: 22px; 
    letter-spacing: -0.02em; 
    color: var(--tp-common-black);
    @media #{$xs} {
        font-size: 25px;
    }
}

.postbox__social-wrapper {
    border-bottom: 1px solid #ECEEF3;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.postbox__comment-form {
    @media #{$md,$xs} {
        margin-bottom: 50px;
    }
}
.aproch-title{
    font-size: 36px;
    margin-bottom: 15px;
}
.approch-item{
    margin-bottom: 65px;
    & ul{
        & li{
            position: relative;
            padding-left: 30px;
            & i{
                color: var(--tp-theme-2);
                font-size: 14px;
                font-weight: 600;
                position: absolute;
                top: 5px;
                left: 0;
            }
        }
    }
}
.approch-thumb{
    @media #{$lg,$md,$xs} {
        margin-bottom: 40px;
    }
    & img{
        border-radius: 10px;
        @media #{$md,$xs} {
            width: 100%;
        }
    }
}

.postbox__comment{
    &-box{
        @media #{$xs} {
            flex-wrap: wrap;
        }  
    }
    &-info {
        flex: 0 0 auto;
    }
}


.rc {
    &__post {
        & ul {
            & li {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }

        &-thumb {
            flex: 0 0 auto;
            & img {
                border-radius: 10px;
                width: 75px;
                height: 75px;
                flex: 0 0 auto;
                object-fit: cover;
            }
        }

        &-title {
            margin-bottom: 6px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: var(--tp-common-black);
            & a {
                &:hover {
                    color: var(--tp-common-blue-4);
                }
            }
        }
    }

    &__meta {
        & span {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 500;
            color: #6A6A74;
        }
    }
}

.sidebar {
    &__wrapper {
        padding-left: 10px;

        @media #{$lg,$md,$xs} {
            padding-left: 0;
        }
    }

    &__widge-title-box{
        border-radius: 14px 14px 0px 0px;
    }
    &__widget-content{
        padding: 40px 30px;
        
        @media #{$xs} {
            padding: 35px 25px;
        }
    }

    &__widget {
        border: 1px solid #E5E5E5;
        border-radius: 14px;
        &-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;    
            color: var(--tp-common-black);
            padding:20px 0px;
            margin: 0px 30px;
            border-bottom: 1px solid #EDEDED;
        }

        &:last-child {
            &-title {
                border-bottom: 0;
            }
        }
        & ul {
            & li {
                list-style: none;
                margin-bottom: 25px;
                &:last-child{
                    margin-bottom: 0;
                }
                & a {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 12px;
                    color: #5F6168;
                    display: flex;
                    justify-content: space-between;
                    &:hover {
                        color: var(--tp-common-blue-4);
                    }
                    & i{
                        font-size: 18px;
                        transform: translateY(2px);
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &__search {
        position: relative;
        & input {
            width: 100%;
            height: 60px;
            line-height: 60px;
            padding: 0 25px;
            text-transform: capitalize;
            @include border-radius(7px);
            border: 0;
            outline: none;
            padding-right: 60px;
            border: 1px solid #E5E5E5;
            border-radius: 14px;
            @media #{$xs} {
                padding-right: 40px;
                padding-left: 15px;
            }
            &:focus{
                border-color: var(--tp-common-blue-4);
            }
            &::placeholder {
                color: #777777;
            }
        }

        & button {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 0 24px;
            color: #fff;
            line-height: 60px;
            @include border-radius(0 7px 7px 0);
            color: var(--tp-common-black);
        }

    }

    &__banner {
        &-content {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
            background: var(--tp-theme-1);

            & h4 {
                padding: 15px 20px;
                font-size: 24px;
                color: var(--tp-common-white);
                text-transform: uppercase;
                margin-bottom: 0;
            }
        }
    }
}

.tagcloud {
    & a {
        border: 1px solid #F2F4F6;
        color: #8A879F;
        display: inline-block;
        line-height: 1;
        padding: 10px 25px;
        margin-bottom: 8px;
        margin-right: 8px;
        border-radius: 30px;
        transition: .3s;
        font-weight: 500;
        font-size: 14px;
        @media #{$lg}{
            padding: 8px 15px;
            font-size: 13px;
        }
        @media #{$sm}{
            padding: 10px 15px;
            font-size: 13px;
        }
        @media #{$xs}{
            padding: 10px 15px;
            font-size: 13px;
        }
        &:hover {
            background: var(--tp-common-blue-4);
            color: var(--tp-common-white);
            border-color: var(--tp-common-blue-4);
        }
    }
    & span{
        font-weight: 600;
        font-size: 16px;
        line-height: 12px;
        color: #202124;
        margin-right: 8px;
    }
}

.basic-pagination{
    & ul{
        border: 1px solid #EBEBEB;
        border-radius: 100px;
        display: inline-block;
        padding: 10px;
       & li {
            display: inline-block;
            & a{
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 50%;
                font-size: 16px;
                font-weight: 600;
                transition: .3s;
            }
            & span{
                display: inline-block;
                transform: translateY(-4px);
            }
            & a{
                &.current{
                    background: #F2F2F2;
                    color: var(--tp-common-black);
                }
                &:hover{
                    background: #F2F2F2;
                    border-color: #F2F2F2;
                    color: var(--tp-common-black);
                } 
            }
        }
    } 
}

.blog-grid-avata-box{
    & .tp-blog-author-info{
        & h5{
            font-weight: 700;
            font-size: 17px;
            line-height: 16px;
            color: #FFFFFF;
        }
        & span{
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: rgba(255, 255, 255, 0.7);
        }
    }
}

.blog-details{
    &-title-box{
        & span{
            font-weight: 600;
            font-size: 14px;
            line-height: 12px;
            color: #FFFFFF;
            padding: 8px 20px;
            border: 1.5px solid rgba(255, 255, 255, 0.2);
            border-radius: 20px; 
            margin-bottom: 10px;
            display: inline-block;
        }
    }
    &-banner-title{
        font-weight: 700;
        font-size: 70px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        padding-bottom: 25px;
        @media #{$lg}{
            font-size: 57px;
        }
        @media #{$md}{
            font-size: 50px;
        }
        @media #{$xs}{
            font-size: 45px;
        }
        @media #{$sm}{
            font-size: 40px;
        }
    }
    &-banner{
        & .tp-blog-author-info{
            & h5{
                font-weight: 800;
                font-size: 17px;
                line-height: 16px;  
                color: #FFFFFF;
            }
            & span{
                font-weight: 600;
                font-size: 13px;
                line-height: 10px; 
                color: rgba(255, 255, 255, 0.7);
            }
        }
    }
    &-social-box{
        @media #{$xs}{
            margin-top: 30px;
        }
        & a{
            height: 42px;
            width: 42px;
            line-height: 40px;
            border-radius: 50%;
            border: 1.5px solid rgba(255, 255, 255, 0.2);
            border-radius: 100px;
            display: inline-block;
            text-align: center;
            color: var(--tp-common-white);
            margin-left: 6px;
            transition: .3s;
            @media #{$md}{
                height: 35px;
                width: 35px;
                line-height: 33px;
                margin-left: 0px;
                font-size: 13px;
            }
            @media #{$xs}{
                margin-left: 0px;
                margin-right: 6px;
            }
            &:hover{
                background-color: var(--tp-common-yellow-4);
                border-color: var(--tp-common-yellow-4);
                color: var(--tp-common-black);
            }
        }
    }
    &-big-img{
        margin-top: -200px;
        position: sticky;
        top: 100px;
        & img{
            border-radius: 30px;
            height: auto;
        }
    }
}

.postbox__details{
    &-checkmark{
        padding-bottom: 40px;
        & ul{
            & li{
                list-style-type: none;
                position: relative;
                padding-left: 35px;
                margin-bottom: 20px;
                font-weight: 500;
                font-size: 17px;
                line-height: 1.2;
                color: #202124;
                @media #{$xs}{
                    font-size: 14px;   
                }
                &::last-child{
                    margin-bottom: 0;
                }
                & i{
                    height: 20px;
                    width: 20px;
                    background-color: var(--tp-common-black);
                    color: var(--tp-common-white);
                    border-radius: 50%;
                    line-height: 20px;
                    text-align: center;
                    font-size: 10px;
                    transform: translateX(2px);
                    position: absolute;
                    top: -2px;
                    left: 0;
                }
            }
        }
    }
    &-title{
        font-weight: 700;
        font-size: 34px;
        line-height: 1.2; 
        color: #080829;
        padding-bottom: 10px;
    }
    &-title-box{
        & p{
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #5F6168; 
        }
    }
    &-img-box{
        padding-bottom: 20px;
        & span{
            & img{
                border-radius: 20px;
                margin-bottom: 20px;
            }
        }
    }
    &-img-caption{
        font-weight: 400;
        font-size: 13px;
        line-height: 10px;
        color: #87888A; 
        & span{
            font-weight: 500;
            font-size: 13px;
            line-height: 10px;
            color: #5F6168;
        }
    }
}
.postbox__details{
    &-wrapper{
        @media #{$md,$xs}{
            padding-right: 0;
        }
    }
    &-qoute{
        background: linear-gradient(90.21deg, #FAFAFA -0.62%, rgba(255, 255, 255, 0) 18.02%);
        padding: 40px 50px;
        border-left: 3px solid var(--tp-common-blue-4);
        @media #{$lg}{
            padding: 40px 40px;
        }
        @media #{$xs}{
            padding:30px;
        }
        & blockquote{
            margin-bottom: 0;
            @media #{$xs}{
                flex-wrap: wrap;
            }
        }
    }
    &-qoute-icon{
        & svg{
            transform: translateY(5px);
            margin-right: 30px;
            @media #{$xs}{
                margin-bottom: 20px;
            }
        }
    }
    &-qoute-text{
        & P{
            font-weight: 500;
            font-size: 23px;
            line-height: 34px; 
            color: #202124;
            @media #{$lg}{
                font-size: 21px;
            }
            @media #{$xs}{
                font-size: 17px;
                line-height: 26px;
            }
        }
        & span{
            font-weight: 500;
            font-size: 15px;
            line-height: 14px;
            color: var(--tp-common-blue-4);
            &::before{
                content: '';
                height: 1px;
                width: 16px;
                background-color: var(--tp-common-blue-4);
                display: inline-block;
                transform: translateY(-4px);
                margin-right: 10px;
            }
        }
    }
    &-author-info-box{
        background-color:#F8F8F8;
        border-radius: 14px;
        padding: 50px 45px;
        @media #{$xs}{
            flex-wrap: wrap;
            padding: 40px 30px;
        }
    }
    &-author-avata{
        flex: 0 0 auto;
        margin-right: 25px;
        @media #{$xs}{
            margin-bottom: 20px;
        }
        & img{
            flex: 0 0 auto;
            border-radius: 50%;
        }
    }
    &-author-title{
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
        color: #202124;
    }
    &-author-social{
        & a{
            height: 36px;
            width: 36px;
            border-radius: 50%;
            text-align: center;
            line-height: 38px;
            box-shadow: 0px 1px 1px rgba(32, 33, 36, 0.12);
            display: inline-block;
            font-size: 12px;
            margin-right: 6px;
            transition: .3s;
            background-color: var(--tp-common-white);
            &:hover{
                background-color: var(--tp-common-blue-4);
                color: var(--tp-common-white);
            }
        }
    }
}

.postbox__comment-input{
    position: relative;
    & input:focus ~ .floating-label,input:not(:focus):valid ~ .floating-label{
        top: 0;
        left: 20px;
        font-size: 13px;
        font-weight: 400;
        color: var(--tp-common-blue-4);
        background-color: var(--tp-common-white);
        padding: 0px 4px;
    }
    & textarea:focus ~ .floating-label-2,textarea:not(:focus):valid ~ .floating-label-2{
        top: -9px;
        left: 20px;
        font-size: 13px;
        font-weight: 400;
        color: var(--tp-common-blue-4);
        background-color: var(--tp-common-white);
        padding: 0px 4px;
    }
    & .eye-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        cursor: pointer;
        & span{
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
        & .eye-on{
            transform: scale(0);
        }
        &.open{
            & .eye-on{
                transform: scale(1) translateY(-50%);
            }
            & .eye-off{
                transform: scale(0);
            }
        }
    }
    & .inputText {
        border-radius: 12px;
        padding: 0px 20px;
        font-size: 14px;
        width: 100%;
        height: 55px;
        border: 0;
        outline: 0;
        font-weight: 500;
        font-size: 15px;
        color: #87888A;
        box-shadow: inset 0 0 0 1px #E5E5E5;
        transition: box-shadow .3s cubic-bezier(.3,0,0,.3);
        color: var(--tp-common-black);
        &:focus{
            box-shadow: 0px 1px 2px 1px rgba($color: #202124, $alpha: .06), inset 0 0 0 2px #600EE4;          
        }
        &.password{
            padding-right: 55px;
        }
    }
    & .textareaText{
        border-radius: 12px;
        padding:20px;
        font-size: 14px;
        width: 100%;
        height: 160px;
        resize: none;
        border: 0;
        outline: 0;
        font-weight: 500;
        font-size: 15px;
        color: #87888A;
        box-shadow: inset 0 0 0 1px #E5E5E5;
        transition: box-shadow .3s cubic-bezier(.3,0,0,.3);
        color: var(--tp-common-black);
        &:focus{
            box-shadow: 0px 1px 2px 1px rgba($color: #202124, $alpha: .06), inset 0 0 0 2px #600EE4;          
        }
    }
    & .floating-label {
        position: absolute;
        pointer-events: none;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.2s ease all;
    }
    & .floating-label-2{
        position: absolute;
        pointer-events: none;
        left: 20px;
        top: 20px;
        transition: 0.2s ease all; 
    }
}

                