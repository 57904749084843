@use '../utils' as *;


.about{
    &-shape-1{
        position: absolute;
        top: 10%;
        left: 0;
    }
    &-shape-2{
        position: absolute;
        top: 0%;
        right: 0%;
    }
    &-banner{
        background-color: #600EE4;;
        height: 540px;
        @media #{$md}{
            height: 580px;
        }
        @media #{$xs}{
            height: 400px;
        }
    }
    &-banner-title{
        font-style: normal;
        font-weight: 600;
        font-size: 110px;
        line-height: 1.2;
        letter-spacing: -0.02em;
        color: var(--tp-common-white);
        position: relative;
        z-index: -1;
        margin-top: 190px;
        display: inline-block;
        margin-left: 100px;
        @media #{$md}{
            font-size: 90px;
            margin-top: 150px;
        }
        @media #{$xs}{
            font-size: 50px;
            margin-left: 50px;
            margin-top: 132px;
        }
    }
}
.about-img{
    &-height{
        min-height: 620px;
        background-repeat: no-repeat; 
        background-size: cover;
        border-radius: 30px;  
        margin-top: -215px;
        overflow: hidden;
        @media #{$xl}{
            min-height: 550px;
        }
        @media #{$lg}{
            min-height: 550px;
        }
        @media #{$md}{
            min-height: 400px;
            margin-top: -320px;
        }
        @media #{$xs}{
            min-height: 300px;
            margin-top: -200px;
        }
    }
    &-title{
        font-style: normal;
        font-weight: 600;
        font-size: 110px;
        line-height: 1.2;
        letter-spacing: -0.02em;
        color: #600EE4;
        margin-left: 100px;
        margin-top: -140px;
        @media #{$md}{
            font-size: 90px;
            margin-top: -115px;
        }
        @media #{$xs}{
            font-size: 50px;
            margin-left: 50px;
            margin-top: -67px;
        }
    }
}

.breadcrumb{
    &__shape-1{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
    &__shape-2{
        position: absolute;
        top: 0;
        right: 0;
    }
    &__sub-img{
        position: absolute;
        top: 35px;
        right: -5px;
    }
    &-height{
        padding-top: 115px;
        padding-bottom: 35px;
    }
    &-height-2{
        padding-top: 105px;
        padding-bottom: 80px;
    }
    &-height-3{
        padding-top: 125px;
        padding-bottom: 290px;
    }
    &-ptb-3{
        padding-top: 155px;
        padding-bottom: 40px;
        & .breadcrumb__content{
            margin-top: 25px;
        }
    }
    &-ptb-4{
        padding-top: 190px;
        padding-bottom: 260px; 
        @media #{$xs}{
            padding-top: 150px;
            padding-bottom: 240px;
        }  
    }
    &-ptb-5{
        padding-top: 180px;
        padding-bottom: 305px;
        @media #{$xl}{
            padding-left: 100px;
            padding-right: 100px;
        }   
    }
    &-overlay{
        position: relative;
        &::after{
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(229, 174, 255, 0.2);
            backdrop-filter: blur(130px);
        } 
        & .breadcrumb__text{
            & p{
                color: rgba(95, 97, 104, 0.7);
            }
        }   
    }
    &__content{
        margin-top: 60px;
        @media #{$md}{
            transform: translateY(20px);
        }
        @media #{$xs}{
            margin-bottom: 30px;
        }
    }
    &__client-info{
        & span{
            font-weight: 500;
            font-size: 14px;
            line-height: 12px; 
            text-align: center;
            letter-spacing: 0.06em;
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 15px;
            display: inline-block;
        }
        & p{
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: var(--tp-common-white);
            margin-bottom: 0;
            @media #{$lg}{
                font-size: 15px;
            }
        }
    }
    &__text {
        & p{
            font-weight: 500;
            font-size: 17px;
            line-height: 26px;
            text-align: center; 
            color: rgba(255, 255, 255, 0.7);
            @media #{$xs}{
                & br{
                    display: none;
                }
            }
        }
        & span{
            font-weight: 600;
            font-size: 14px;
            line-height: 12px;
            text-transform: uppercase; 
            color: rgba(255, 255, 255, 0.6);
            padding-bottom: 10px;
            display: inline-block;            
        }
    }
    &__img{
        display: inline-block;
    }
    &__title{
        font-weight: 700;
        font-size: 70px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        padding-bottom: 12px;
        @media #{$lg}{
            font-size: 60px;
        }
        @media #{$md}{
            font-size: 55px;
        }
        @media #{$xs}{
            font-size: 40px;
            line-height: 1.2;
        }
        &.anime_text{
            transform:translateX(80px);
            overflow: hidden;
            opacity: 0;
            font-style: normal;
        }
    }
    &__list{
        display: inline-block;
        & span{ 
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            color: #FFFFFF;
            @media #{$xs}{
                font-size: 15px;
            }
            &.child-one{
                color: rgba(255, 255, 255, 0.7);
                font-weight: 400;
            }        
            &.dvdr{
                & i{
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 16px;
                    color: rgba(255, 255, 255, 0.7);
                    padding: 0px 8px;
                    transform: translateY(1px);
                }
            }
        }
    }
}

.pd-border-right{
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    & .space-left-2{
        padding-left: 50px;
        @media #{$lg}{
            padding-left: 30px;
        }
        @media #{$xs}{
            padding-left: 0px;
        }
    }
    & .space-left-3{
        padding-left: 50px;
        @media #{$lg}{
            padding-left: 30px;
        }
        @media #{$md}{
            padding-left: 0px;
        }
        @media #{$xs}{
            padding-left: 0px;
        }
    }
    & .space-left-4{
        padding-left: 50px;
        @media #{$lg}{
            padding-left: 30px;
        }
        @media #{$xs}{
            padding-left: 0px;
        }
    }
}

.breadcrumb-height-3{
    & .container{
        & .row{
            [class*="col-"]{
                &:last-child{
                    border-right: 0;
                }
                @media #{$md}{
                    &:nth-child(2){
                        border-right: none;
                    }
                }
                @media #{$xs}{
                    border-right: none;
                }
            }
        }
    }
}

.breadcrumb{
    &__shape-3{
        position: absolute;
        bottom: 12%;
        left: 8%;
        -moz-animation: circle-animations 3s ease-in-out 1s forwards infinite alternate;
        -webkit-animation: circle-animations 3s ease-in-out 1s forwards infinite alternate;
        animation:circle-animations 3s ease-in-out 1s forwards infinite alternate;
    }
    &__shape-4{
        position: absolute;
        bottom: 12%;
        left: 9%;
        @media #{$md}{
            bottom: 50%;
        }
    }
}

.breadcrumb__content-wrap{
    @media #{$xl}{
        padding-left: 80px;   
    }
    @media #{$lg}{
        padding-left: 50px;   
    }
    @media #{$md}{
        padding-left: 50px;   
    }
}


