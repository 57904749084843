@use '../utils' as *;

/*----------------------------------------*/
/*  21. RANK CSS START
/*----------------------------------------*/

.tp-rank{
    &__feature{
        margin-bottom: 50px;
        & ul{
            & li{
                font-weight: 500;
                font-size: 16px;
                line-height: 1.1;
                position: relative;
                padding-left: 30px;
                margin-bottom: 20px;
                color: var(--tp-common-black);
                list-style-type: none;
                &:last-child{
                    margin-bottom: 0;
                }
                & i{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    line-height: 20px;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 8px;
                    color: var(--tp-common-white);
                    background-color: var(--tp-common-orange);
                    font-weight: 400;
                }
            }
        }
    }
    &__wrapper{
        padding-left: 70px;
        @media #{$lg}{
            padding-left: 5px;
        }
        @media #{$md}{
            margin-bottom: 150px;
        }
        @media #{$xs}{
            padding-left: 0;
            margin-bottom: 70px;
        }
    }
    &__circle-shape-1{
        position: absolute;
        left: 41%;
        bottom: -24%;
    }
    &__circle-shape-2{
        position: absolute;
        left: 51%;
        bottom: -35%;
    }
    &__circle-shape-3{
        position: absolute;
        left: 38%;
        bottom: -39%;
    }
    &__circle-shape-4{
        position: absolute;
        left: 48%;
        bottom: -29%;
    }
    &__circle-shape-5{
        position: absolute;
        left: 45%;
        bottom: -37%;
    }
    &__circle-shape-6{
        position: absolute;
        left: 43%;
        bottom: -31%;
    }
    &__circle-shape-7{
        position: absolute;
        left: 45%;
        bottom: -46%;
    }
    &__bg-shape{
        position: absolute;
        top: -35px;
        right: 65px;
        z-index: -1;
        transform: rotate(-12deg);
    }
    &__item{
        box-shadow: 0px 20px 40px rgba(1, 16, 61, 0.14);
        background-color: var(--tp-common-white);
        display: inline-block;
        text-align: center;
        border-radius: 20px;
        max-width: 150px;
        padding: 55px 15px 30px 15px;
        margin: 0 -10px 0 -10px;
        transition: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
        @media #{$lg}{
            margin: 0 -12px 0 -12px;
        }
        @media #{$xs}{
            margin: 0;
            width: 140px;
        }
        @media #{$sm}{
            width: 120px;
        }
        &:nth-child(2){
            transform: translateY(30px);
            @media #{$xs}{
                transform: translateY(0px);
            }
        }
        &:nth-child(3){
            transform: translateY(70px);
            @media #{$xs}{
                transform: translateY(0px);
            }
        }
        &.active{
            background: linear-gradient(16.46deg, #5956E9 11.15%, #9B99FF 99.48%);
            box-shadow: 0px 14px 20px rgba(1, 16, 61, 0.1);
            border-radius: 20px;
            & .tp-rank__number{
                & i{
                    color: var(--tp-common-white);
                    opacity: .3;
                }
                & span{
                    color: var(--tp-common-white);
                }
            }
            & .tp-rank__company{
                & span{
                    background-color: var(--tp-common-white);
                    border-color: var(--tp-common-white);
                }
            }
            & .tp-rank__company-info{
                & a{
                    color: var(--tp-common-white);
                }
                & span{
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }
        &:hover{
            webkit-transform: translate(-20px, -30px);
            -ms-transform: translate(-20px, -30px);
            transform: translate(-20px, -30px);
            transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
        }
    }
    &__cup{
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        text-align: center;
        & span{
            height: 25px;
            width: 25px;
            line-height: 17px;
            border-radius: 50%;
            text-align: center;
            display: inline-block;
            background-color: var(--tp-common-white);
            border: 2px solid var(--tp-theme-1);
        }
    }
    &__number{
        margin-bottom: 40px;
        margin-left: -25px;
        & i{
            font-weight: 400;
            font-size: 24px;
            color: var(--tp-common-black);
            opacity: .3; 
            margin-right: 7px;
        }
        & span{
            font-weight: 500;
            font-size: 66px;
            color: var(--tp-common-black);
            display: inline-block;
        }
    }
    &__company{
        margin-bottom: 12px;
        & span{
            height: 36px;
            width: 36px;
            line-height: 30px;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            background-color: var(--tp-common-white);
            border: 1px solid rgba(1, 16, 61, 0.1); 
        }
    }
    &__company-info{
        & a{
            font-weight: 600;
            font-size: 15px;
            color: var(--tp-common-black);
        }
        & span{
            font-weight: 500;
            font-size: 12px;
        }
    }
}