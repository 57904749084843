	// bg color
	@mixin bg-color($color, $opacity) {
	    background-color: rgba($color, $opacity);
	}

	// placeholder input
	@mixin tp-placeholder {
	    &::-webkit-input-placeholder {
	        @content;
	    }

	    &:-moz-placeholder {
	        @content;
	    }

	    &::-moz-placeholder {
	        @content;
	    }

	    &:-ms-input-placeholder {
	        @content;
	    }
	}

	// filter 
	@mixin filter($value) {
	    -webkit-filter: $value;
	    filter: $value;
	}


	@mixin tp-gradient($value, $type : "linear") {

        @if $type == linear {
            background-image: -webkit-linear-gradient($value);
            background-image: -moz-linear-gradient($value);
            background-image: -ms-linear-gradient($value);
            background-image: -o-linear-gradient($value);
            background-image:   linear-gradient($value);
          } @else {
            background-image: -webkit-radial-gradient($value);
            background-image: -moz-radial-gradient($value);
            background-image: -ms-radial-gradient($value);
            background-image: -o-radial-gradient($value);
            background-image:   radial-gradient($value);
          }
       
    }



	// appearance for select
	@mixin appearance($value) {
	    -webkit-appearance: $value;
	    -moz-appearance: $value;
	    -ms-appearance: $value;
	    -o-appearance: $value;
	    appearance: $value;
	}

	// keyframes 
	@mixin keyframes($name) {
	    @-webkit-keyframes #{$name} {
	        @content;
	    }

	    @-moz-keyframes #{$name} {
	        @content;
	    }

	    @-ms-keyframes #{$name} {
	        @content;
	    }

	    @keyframes #{$name} {
	        @content;
	    }
	}

	//backgroud 
	@mixin background {
	    background-position: center;
	    background-size: cover;
	    background-repeat: no-repeat;
	}

	//transition
	@mixin transition($time) {
	    -webkit-transition: all $time ease-out 0s;
	    -moz-transition: all $time ease-out 0s;
	    -ms-transition: all $time ease-out 0s;
	    -o-transition: all $time ease-out 0s;
	    transition: all $time ease-out 0s;
	}

	// transform
	@mixin transform($transforms) {
	    -webkit-transform: $transforms;
	    -moz-transform: $transforms;
	    -ms-transform: $transforms;
	    -o-transform: $transforms;
	    transform: $transforms;
	}

	//border
	@mixin border-radius($man) {
	    -webkit-border-radius: $man;
	    -moz-border-radius: $man;
	    -o-border-radius: $man;
	    -ms-border-radius: $man;
	    border-radius: $man;
	}

	// sentence case
	@mixin sentence-case() {
	    text-transform: lowercase;

	    &:first-letter {
	        text-transform: uppercase;
	    }
	}

	// Flexbox display
	@mixin flexbox() {
	    display: -webkit-box;
	    display: -moz-box;
	    display: -ms-flexbox;
	    display: -webkit-flex;
	    display: flex;
	}

	// Box shadows
	@mixin box-shadow($shadow) {
	    -webkit-box-shadow: $shadow;
	    -moz-box-shadow: $shadow;
	    -ms-box-shadow: $shadow;
	    -o-box-shadow: $shadow;
	    box-shadow: $shadow;
	}

	@mixin top-to-bottom() {
	    backface-visibility: hidden;
	    -webkit-transform: translate3d(0, 0, 0);
	    -moz-transform: translate3d(0, 0, 0);
	    transform: translate3d(0, 0, 0);
	    -webkit-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
	    -moz-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
	    transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
	}

	@mixin top-to-bottom-hover() {
	    -webkit-transform: translate3d(0, -10px, 0);
	    -moz-transform: translate3d(0, -10px, 0);
	    transform: translate3d(0, -10px, 0);
	}

	@mixin title-animation(){
		-webkit-transform: translateY(5px);
		-moz-transform: translateY(5px);
		-ms-transform: translateY(5px);
		-o-transform: translateY(5px);
		transform: translateY(5px);
		-webkit-transition: all 0.3s ease-out 0s;
		-moz-transition: all 0.3s ease-out 0s;
		-ms-transition: all 0.3s ease-out 0s;
		-o-transition: all 0.3s ease-out 0s;
		transition: all 0.3s ease-out 0s;
		transition-delay: 0s;
		
		transition-delay: 0.2s;
	}
	@mixin title-animation-hover(){
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
		transition-delay: 0.2s;
	}

	@mixin subtitle-animation(){
		-webkit-transform: translateY(5px);
		-moz-transform: translateY(5px);
		-ms-transform: translateY(5px);
		-o-transform: translateY(5px);
		transform: translateY(5px);
		-webkit-transition: all 0.3s ease-out 0s;
		-moz-transition: all 0.3s ease-out 0s;
		-ms-transition: all 0.3s ease-out 0s;
		-o-transition: all 0.3s ease-out 0s;
		transition: all 0.3s ease-out 0s;
		transition-delay: 0s;
		visibility: hidden;
		opacity: 0;
		transition-delay: 0s;
	}
	@mixin subtitle-animation-hover(){
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
		transition-delay: 0.4s;
	}


	@mixin title-animation-top-bottom(){
		font-size: 26px;
		font-weight: 500;
		color: var(--tp-common-black);
		visibility: hidden;
		opacity: 0;
		-webkit-transform: translateX(5px);
		-moz-transform: translateX(5px);
		-ms-transform: translateX(5px);
		-o-transform: translateX(5px);
		transform: translateX(5px);
		-webkit-transition: all 0.3s ease-out 0s;
		-moz-transition: all 0.3s ease-out 0s;
		-ms-transition: all 0.3s ease-out 0s;
		-o-transition: all 0.3s ease-out 0s;
		transition: all 0.3s ease-out 0s;
		transition-delay: 0s;
		transition-delay: 0.2s;
	}
	@mixin title-animation-top-bottom(){
		font-size: 16px;
		font-weight: 500;
		color: var(--tp-common-black);
		-webkit-transform: translateX(5px);
		-moz-transform: translateX(5px);
		-ms-transform: translateX(5px);
		-o-transform: translateX(5px);
		transform: translateX(5px);
		-webkit-transition: all 0.3s ease-out 0s;
		-moz-transition: all 0.3s ease-out 0s;
		-ms-transition: all 0.3s ease-out 0s;
		-o-transition: all 0.3s ease-out 0s;
		transition: all 0.3s ease-out 0s;
		transition-delay: 0s;
		visibility: hidden;
		opacity: 0;
		transition-delay: 0s;
	}